import React from "react";
import Images from "../../constants/images";

const ProductDetails = ({ productDetails }) => {
  const getNewFields = (details) => {
    var newDetails = {};
    if (details?.medicine) {
      const { medicineNameDetailed, mrp, quantity } = details?.medicine;
      const { manufacturerName, maxDiscount } = details?.batch;
      newDetails = {
        ...newDetails,
        medicine_name: medicineNameDetailed,
        quantity: quantity,
        mrp,
        manufacturer_name: manufacturerName,
        discount_percentage: maxDiscount,
      };
    } else {
      newDetails = { ...details };
    }

    return newDetails;
  };

  let details = getNewFields(productDetails);

  var discountedPrice;
  if (details) {
    discountedPrice =
      (details?.mrp - (details?.mrp / 100) * details?.discount_percentage).toFixed(2);
  }

  return (
    <div className="product-details-container d-flex p-common-2 border-bottom-primary">
      <div className="product-image-container">
        <img src={Images?.product} alt="" className="product-image" />
      </div>

      <div className="product-details d-flex flex-column text-size-primary">
        <p className="text-color-primary text-family-bold">
          {details?.medicine_name}
        </p>
        <p className="text-color-micro">{details?.manufacturer_name}</p>
        <p className="text-color-primary">
          MRP{" "}
          <span
            style={{
              textDecoration:
                parseInt(details?.discount_percentage) > 0
                  ? ""
                  : null,
            }}
          >
            ₹{details?.mrp}
          </span>
          {parseInt(details?.discount_percentage) > 0 ? (
            <span className="discount-text mx-2">
              {details?.discount_percentage}% off
            </span>
          ) : null}
        </p>
        <p className="text-color-primary"style={{paddingLeft:'36px'}}>
          ₹{`${discountedPrice}  Qty ${productDetails?.quantity || 0}::${
            productDetails?.loose || 0
          }`}
        </p>
      </div>
    </div>
  );
};

export default ProductDetails;
