import logo from "./logo.svg";
import "./index.css";
import HomePage from "./screens/home";
import { Route, Switch } from "react-router-dom";
import routes from "./routes";

function App() {
  return (
    <>
      <Switch>
        {routes?.map((route, index) => {
          return (
            route.component && (
              <Route
                key={index}
                path={route?.path}
                render={(props) => {
                  return <route.component {...props} />;
                }}
                exact={route?.exact}
              />
            )
          );
        })}
        <Route path="/" exact={true} component={HomePage} />
        <Route path="/:id" exact={true} component={HomePage} />
      </Switch>
    </>
  );
}

export default App;
