import React from "react";

const BottomBox = ({ orderDetails }) => {
  return (
    <div className="bottom-box-container">
      <div className="bottom-box-text">Made a mistake?</div>
      <a
        href={`tel:${orderDetails?.retailer?.contact_no}`}
        className="bottom-box-button"
      >
        Call Pharmacy
      </a>
    </div>
  );
};

export default BottomBox;
