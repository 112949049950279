import home_icon from "./home.svg";
import pedestrian from "./pedestrian.svg";
import skip_forward from "./skip_forward.svg";
import close from "./close.svg";
import product from "./product.jpeg";
import profile from "./profile.jpeg";
import chevron_up from "./chevron-up.png";
import homeWhite from "./homeWhite.svg";
import chevron_down from "./chevronUp.svg";
import scheduledImage from "./status/scheduledImage.svg";
import terminated from "./status/terminated.svg";
import pickup from "./status/pickup.svg";
import scheduledBg from "./home/scheduledBg.svg";
import scheduledRight from "./home/scheduledRight.svg";
import nullScreen from "./home/nullScreen.svg";
import calendar from "./home/calendar.svg";
import drawerImage from "./drawerImage.png";

const Images = {
  home_icon,
  pedestrian,
  skip_forward,
  close,
  product,
  profile,
  chevron_up,
  homeWhite,
  chevron_down,
  scheduledImage,
  terminated,
  pickup,
  scheduledBg,
  scheduledRight,
  nullScreen,
  calendar,
  drawerImage,
};

export default Images;
