import axios from "axios";
import { apiBaseUrl } from "./apis";
import axiosRetry from "axios-retry";

const apiInstance = () => {
  const api = axios.create({
    baseURL: apiBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {
    // let accessToken = localStorage.getItem(localStorageConstants.accessToken);
    // if (accessToken) {
    //   if (config.method !== "OPTIONS") {
    //     config.headers.authorization = `Bearer ${accessToken}`;
    //   }
    // }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("ERROR", error, error.response);
      //throw error.response;
      throw error;
    }
  );

  return api;
};

const apiClient = apiInstance();

export default apiClient;
