import React from "react";

const NullScreen = ({ title, image, description }) => {
  return (
    <div
      className="main-status-container d-flex justify-content-center"
      style={{ height: "90vh" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={image} />
        <div className="d-flex flex-column align-items-center mt-4">
          <p className="text-family-bold text-color-secondary text-size-primary">
            {title}
          </p>
          <p
            className="text-wrapper text-size-primary mt-2"
            style={{ color: "gray", width: "100%" }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NullScreen;
