import RenderStatus from "../screens/renderStatus";

var routes = [
  {
    path: "/:id/status",
    component: RenderStatus,
    exact: true,
  },
];

export default routes;
