import apiClient from "../../apis/api-client";
import apiUrls from "../../apis/apis";

const getBill = async (
  query,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(apiUrls?.service?.getBill(query));
    console.log(data, "getBill-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "getBill-error");
    failCallback();
  }
};

const repeatOrder = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls?.service?.repeatOrder,
      dataToSend
    );
    console.log(data, "repeatOrder-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "repeatOrder-error");
    failCallback();
  }
};

export { getBill, repeatOrder };
