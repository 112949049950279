import React from "react";
import Images from "../../constants/images";

const CustomerDetails = ({ orderDetails }) => {
  return (
    <div className="profile-container d-flex justify-content-between align-items-center">
      <div className="profile-info d-flex flex-column">
        <p className="text-size-primary text-color-secondary">
          {orderDetails?.patient?.patientName || "NA"}
        </p>
        <p className="text-size-micro text-micro profile-info-number">
          +91 {orderDetails?.patient?.patientPhone || "NA"}
        </p>
      </div>

      <div className="profile-image-container">
        <img
          src={Images?.profile}
          className="profile-image"
          alt="Profile Image"
        />
      </div>
    </div>
  );
};

export default CustomerDetails;
