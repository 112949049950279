import React, { useState, useEffect } from "react";
import Loader from "../loader";
import "./styles.css";

const CustomModal = ({
  primaryCtaFunction,
  primaryText = "Label",
  closeModalRef,
  openModalRef,
}) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (input) {
      setError("");
    }
  }, [input]);

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
      >
        <div className="modal-dialog-centered modal-dialog d-flex justify-content-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {primaryText}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex modal-input-container">
                <input
                  value={input}
                  onChange={(e) => {
                    setInput(e.target?.value);
                  }}
                  type="text"
                  className="modal-input"
                  style={{ borderColor: error ? "red" : "#4ab37c" }}
                />
                {error ? (
                  <div
                    style={{ textAlign: "center", color: "red", fontSize: 14 }}
                    className="mt-2"
                  >
                    {error}
                  </div>
                ) : null}
                <button
                  className="modal-confirm-button"
                  onClick={() => {
                    primaryCtaFunction(input, setError);
                  }}
                >
                  Confirm Schedule
                </button>
              </div>
            </div>
          </div>
          <button
            className="schedule-card-button d-none"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            ref={openModalRef}
          ></button>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
