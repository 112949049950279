import React from "react";

const SellerDetails = ({ orderDetails }) => {
  let whatsappText = `Hii ${
    orderDetails?.retailer?.store_name || "Retailer"
  }, Thanks for your wonderful service.`;

  let whatsappNum = orderDetails?.retailer?.contact_no?.includes("+91")
    ? orderDetails?.retailer?.contact_no
    : `+91${orderDetails?.retailer?.contact_no}`;

  return (
    <div className="call-to-action d-flex align-items-center justify-content-between p-common border-bottom-primary">
      <div className="call-to-action-text flex-column justify-content-center flex-align-center">
        <p className="text-family-bold text-color-secondary text-size-primary">
          Ordering from
        </p>

        <p className="text-color-primary text-size-primary mt-common">
          {orderDetails?.retailer?.store_name || "NA"}
        </p>
      </div>

      <div className="call-to-action-button-container text-family-bold d-flex">
        <a
          href={`tel:${orderDetails?.retailer?.contact_no}`}
          className="call-to-action-button"
        >
          <i className="fas fa-phone fa-2x"></i>
        </a>
        <a
          href={`https://wa.me/${whatsappNum}?text=${whatsappText}`}
          className="call-to-action-button call-to-action-button-2"
        >
          <i class="fab fa-whatsapp fa-2x"></i>
        </a>
      </div>
    </div>
  );
};

export default SellerDetails;
