import moment from "moment";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader";
import CustomModal from "../../components/modal";
import Images from "../../constants/images";
import { repeatOrder } from "./apis";

const ScheduleCard = ({ orderDetails, setDays }) => {
  const openModalRef = useRef(null);
  const closeModalRef = useRef(null);
  const [loader, setLoader] = useState(false);
  var history = useHistory();
  const onClickSchedule = (input, setError) => {
    var numberRegex = /([1-9][0-9]*|1[0-9]+)/;
    if (!input) {
      setError("This field is required");
      return;
    }

    if (!numberRegex?.test(input)) {
      setError("Only numbers greater than 0 are allowed");
      return;
    }
    var scheduled_start = moment(new Date())?.format("YYYY-MM-DD");
    var scheduled_end = moment(new Date())
      ?.add({ days: parseInt(input) })
      ?.format("YYYY-MM-DD");
    setLoader(true);
    repeatOrder(
      {
        details: orderDetails?.details,
        retailer: orderDetails?.retailer,
        patient: orderDetails?.patient,
        order_id: orderDetails?.order_id,
        invoice: orderDetails?.invoice,
        scheduled_start,
        scheduled_end,
        scheduled_interval: Number(input),
        scheduled_type: "days",
        retailer_code: orderDetails?.retailer_code,
      },
      (data) => {
        if (data?.status === "success") {
          closeModalRef?.current?.click();
          setDays(input);
          history?.push({
            pathname: `/${orderDetails._id}/status`,
            state: "scheduled",
          });
          setLoader(false);
        }
      }
    );
  };
  return (
    <div className="schedule-card-container">
      <div className="schedule-card-text-container d-flex flex-column">
        <p
          className="text-family-bold text-color-secondary pb"
          style={{ fontSize: 17 }}
        >
          Wish to receive this order regularly?
        </p>
        <button
          className="schedule-card-button"
          onClick={() => {
            openModalRef?.current?.click();
          }}
        >
          Schedule
        </button>
      </div>
      <div style={{ position: "relative", right: -13 }}>
        <img src={Images?.scheduledRight} height="125" />
      </div>
      <CustomModal
        primaryCtaFunction={onClickSchedule}
        closeModalRef={closeModalRef}
        openModalRef={openModalRef}
        primaryText="After how many days do you wish to receive this order?"
      />
      <Loader loading={loader} />
    </div>
  );
};

export default ScheduleCard;
