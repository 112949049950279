import React from "react";

const Invoice = ({ orderDetails }) => {
  var discountedPercent =
    (orderDetails?.discount / parseInt(orderDetails?.amount)) * 100;

  return (
    <div className="invoice-container d-flex flex-column p-common-2 text-size-primary border-bottom-primary">
      <p className="text-color-primary text-family-bold">Invoice</p>
      <p className="d-flex justify-content-between text-color-primary mt-2">
        <span>Item total</span>
        <span>₹{orderDetails?.amount}</span>
      </p>
      {parseInt(orderDetails?.discount) > 0 ? (
        <p className="d-flex justify-content-between text-color-primary mt-2">
          <span>Total discount ({Math.round(discountedPercent)}%)</span>
          <span>-₹{orderDetails?.discount}</span>
        </p>
      ) : null}
      <p className="d-flex justify-content-between text-color-primary mt-2 text-family-bold">
        <span>Total Payable</span>
        <span>₹{orderDetails?.net_amount}</span>
      </p>
    </div>
  );
};

export default Invoice;
