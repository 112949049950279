const baseUrlWithSlash = "https://kabootar.digihealth.in/";

const apiUrls = {
  service: {
    getBill: (query) => `service/bill/find/one?${query}`,
    repeatOrder: `service/bill/repeat`,
  },
};

export { baseUrlWithSlash as apiBaseUrl };
export default apiUrls;
