import React from "react";
import Images from "../../constants/images";

const ScheduleSuccess = ({ title }) => {
  return (
    <div className="schedule-success-container">
      <p
        className="text-color-primary px-2"
        style={{ fontSize: 17, width: "54%", fontWeight: "800" }}
      >
        {title}
      </p>
      <img src={Images?.calendar} />
    </div>
  );
};

export default ScheduleSuccess;
