import React, { useEffect, useState } from "react";
import BottomBox from "./bottomBox";
import { pickup, scheduled, terminated } from "./data";
import { useHistory } from "react-router";
import "./style.css";
import { useLocation, useParams } from "react-router-dom";
import { getBill } from "../home/apis";
import Loader from "../../components/loader";
import NullScreen from "../../components/nullScreen";
import Images from "../../constants/images";

const RenderStatus = () => {
  const [orderDetails, setOrderDetails] = useState();
  var location = useLocation();
  const [loader, setLoader] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoader(true);
    getBill(`_id=${id}`, (data) => {
      if (data?.status === "success" && data?.result) {
        setOrderDetails(data?.result);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const [status, setStatus] = useState(
    location?.state ? location?.state : "scheduled"
  );
  let statusData;
  var history = useHistory();

  if (status === "scheduled") {
    statusData = scheduled;
  } else if (status === "terminated") {
    statusData = terminated;
  } else if (status === "pickup") {
    statusData = pickup;
  }

  if (!orderDetails && !loader) {
    return (
      <NullScreen
        title="No Status Found"
        image={Images?.nullScreen}
        description="You have entered wrong order id"
      />
    );
  }

  return (
    <div className="main-status-container">
      <Loader loading={loader} />
      <div className="head-start d-flex p-common-2 justify-content-between">
        <p className="text-family-bold text-color-secondary text-size-head pb">
          Order (#{orderDetails?.order_id})
        </p>
        <div className="call-to-action-button-container mt-2 call-to-button text-family-bold">
          <div
            className="call-to-action-button"
            onClick={() => {
              history?.goBack();
            }}
          >
            Details
          </div>
        </div>
      </div>

      <div className="status-details-container d-flex flex-column justify-content-center align-items-center">
        <img src={statusData?.image} />
        <div className="d-flex flex-column align-items-center mt-4">
          <p className="text-family-bold text-color-secondary text-size-primary">
            {statusData?.text}
          </p>
          <p
            className="text-wrapper text-size-primary mt-2"
            style={{
              color: statusData?.subTextColor,
              fontWeight: statusData?.bold ? 500 : null,
            }}
          >
            {statusData?.subText}
          </p>
        </div>
      </div>

      <BottomBox orderDetails={orderDetails} />
    </div>
  );
};

export default RenderStatus;
