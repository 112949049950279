import Images from "../../constants/images";
var { scheduledImage, terminated, pickup } = Images;

var scheduled = {
  image: scheduledImage,
  text: "Your repeat order is scheduled !",
  subText: "The pharmacy will contact you for further details!",
  subTextColor: "#707070",
  bold: false,
};

var terminated = {
  image: terminated,
  text: "We have notified your pharmacy !",
  subText: "This schedule is terminated",
  subTextColor: "#fb7964",
  bold: true,
};

var pickup = {
  image: pickup,
  text: "We have notified your pharmacy !",
  subText: "Pickup scheduled for tomorrow, 21 Jul 2021",
  subTextColor: "#4386fa",
  bold: true,
};

export { scheduled, terminated, pickup };
